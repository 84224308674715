#ProjectDashboard{
    background-color: #EEF7FF;
    min-height: 100vh;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
}

#ProjectDashboard label{
    font-size: 0.8rem;
    font-weight: 500;
}

#ProjectDashboard .questionare_Table thead tr th{
    font-size: 13px;
}

#ProjectDashboard .form-check-inline{
    margin-right: 0.6rem;
}


#ProjectDashboard .form-check-inline .form-check-input:checked {
    background-color: #0D349B;
    border-color: #0D349B;
}

#ProjectDashboard .form-check .form-check-input:checked {
    background-color: #0D349B;
    border-color: #0D349B;
}


/* #ProjectDashboard .horizontal_line{
    width: 100%;
    height: 1.5px;
    background: #0D349B;
    margin-top: -19px;
} */


#ProjectDashboard .initail_Message{
    height: 68vh;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

#ProjectDashboard .table_initail_Message{
    height: 10vh;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

#ProjectDashboard .css-8ysb6t-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #0D349B;
}

#ProjectDashboard .css-1aquho2-MuiTabs-indicator{
    background-color: #0D349B;
}

#ProjectDashboard .AccordionSummary_border{
    display: flex;
 justify-content: center;
}

/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded .AccordionSummary_border .line{
     border: 1px solid #0D349B;
     transform: translateY(-11px);
     width: 0%;
     animation-name: Tabline;
     animation-duration: 2.5s;
     animation-delay: 0.2s;
     animation-fill-mode: forwards;
    } */


/* @keyframes Tabline {
    0%   { width: 0%;}
    100% { width: 100%;}
} */