.icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.authIcon {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 51px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 35px;
  height: 35px;
  position: relative;
  top: -9px;
  margin: 0px 5px;
}
.icon {
  width: 35px;
  height: 35px;
}
.fbIcon {
  position: relative;
  left: -24px;
}

/* .facebookIcon {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  top: -7px;
  border: transparent;
  background-color: white;
} */

.facebookIcon {
  width: 211px;
  font-size: 11px;
  height: 43px;
  padding: 0;

  box-shadow: 2px solid red;
  margin-top: 5px;
  border-radius: 3px;
  position: relative;
  font-weight: normal;
  text-transform: capitalize;

  color: #fff;
  cursor: pointer;
  display: inline-block;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
}

.googleIcon {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 209px;
}
.paddLeft {
  padding-left: 17px;
}
.body {
  min-height: 140px;
}
