#ChartSummary {}

#ChartSummary .blue-container {
    background-color: #0D349B;
    border-radius: 10px;
    color: white;
    /* padding: 5px 25px; */
    padding: 5px 0px 5px 25px;
}

#ChartSummary .result {
    background-color: #36508D;
    border-radius: 10px;
    padding: 10px;
}

#ChartSummary .result .span1 {
    width: 70px;
    line-height: 1;
}

#ChartSummary .result .span2 {
    font-size: 2rem;
    font-weight: 500;
}

#ChartSummary .table {
    background-color: white !important;
    /* color: black;    */
    border: 1px solid #ced4da;
    border-radius: 10px;
    margin-bottom: 0px;
    border-collapse: separate;
    border-spacing: 0px;

}

#ChartSummary .table .border_bottom {
    border-bottom: 1px solid #ced4da;
}


#ChartSummary .table th,
td {
    font-size: 13px;

}

#ChartSummary .table td {
    /* height: 13px; */
    line-height: 0.7;

}

#ChartSummary .table .b-bottom {
   border-bottom: 1px solid #ced4da;

}

