body {
  margin: 0;
}

.fontSize_11px {
  font-size: 11px !important;
}
.font-size-15px {
  font-size: 15px !important;
}

.canvasjs-chart-credit {
  display: none;
}
