.logo {
  min-height: 32px;
  max-height: 32px;
  min-width: 38px;
  max-width: 38px;
}

.main {
  background-color: rgb(6, 27, 127) !important;
  color: white;
}

.message {
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  flex-direction: column;
}
