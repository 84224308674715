.logo {
  min-height: 32px;
  max-height: 32px;
  min-width: 38px;
  max-width: 38px;
}
.main {
  background-color: rgb(6, 27, 127) !important;
  color: white;
}

.message {
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
}

.line {
  display: inline-block;
  height: 20px;
  width: 1px;
  margin: 0px 0px 0px 8px;
  background-color: white;
  position: relative;
  top: 6px;
}
