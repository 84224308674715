
.manage-video-wrapper{
    /* border-top: 1px solid blue;
    border-left: 1px solid blue;
    border-right: 1px solid blue; */
    padding: 15px;
   }





