.sv_main .sv-action-bar {
  display: flex;
  position: relative;
  align-items: center;
  margin-left: auto;
  padding: 0px 0px 0px 16px;
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-end;
  margin-top: 10px;
}
