.login-form-main {
  height: 100%;
  max-width: 780px;
  background-color: white;
}
.login-wrapper {
  min-height: 100vh;
  background-image: url("../assets/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-side-image {
  max-height: 513px;
  height: 100%;
  max-width: -webkit-fill-available;
}
.btn-primary {
  background-color: #061b7f !important;
  border-color: #061b7f !important;
}
.shadow-custom {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px;
}

.login-form-heading {
  color: #061b7f;
}
/* .form-control-border{
    border-left:4px solid #061b7f !important;
} */

.text-primary {
  color: #061b7f !important;
}
.form-control:focus {
  box-shadow: none !important;
  border-left: 4px solid #061b7f !important;
  border-radius: 0;
  transition: 0.1s ease-in;
}
.footer-branding-text {
  font-size: 8px;
}
.form-control {
  font-size: 12px;
}

.btn {
  font-size: 0.8rem;
}

.slogon-title {
  font-size: 0.8rem;
}
.login-side-image-wrapper-top {
  display: none;
}
.login-form {
  width: 85%;
}
.logo-login-form img {
  max-width: 80px;
}
.logo-login-form span {
  font-size: 0.7rem;
}
.project-detail-card {
  min-height: 200px;
  height: 100%;
  border-radius: 20px !important;
}
.project-title {
  font-weight: 500;
}
.project-description {
  font-size: 0.8rem;
}
.project-footer-info {
  font-size: 0.9rem;
}
.error-message {
  font-size: 10px;
  font-weight: 600;
}
.bg-primary {
  background-color: rgb(6, 27, 127) !important;
}

.fade-enter .online-status {
  opacity: 0;
  transform: translateY(-100%);
}
.fade-enter-active .online-status {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit .online-status {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit-active .online-status {
  opacity: 0;
  transform: translateY(100%);
}
.fade-enter-active .online-status,
.fade-exit-active .online-status {
  transition: opacity 500ms, transform 1000ms;
}
@media only screen and (max-width: 767px) {
  .login-side-image-wrapper-top {
    display: block;
  }
  .login-side-image-wrapper-side {
    display: none;
  }
  .login-side-image {
    width: 100%;
  }
  .login-form {
    width: 100%;
  }
  .logo {
    display: none;
  }
  .login-form-heading,
  .slogon-title,
  .logo-login-form {
    text-align: center;
  }
}
